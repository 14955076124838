import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CButton, CFormSelect, CTable, CToast, CToastBody, CToaster } from '@coreui/react'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { CSSProperties } from "react";
import GridLoader from "react-spinners/GridLoader";
import { ShimmerTable } from "react-shimmer-effects";
import Modal from 'react-modal';
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineSecurityScan } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";


const QuickScans = () => {

  //const [toast, addToast] = useState(0)
  const navigate = useNavigate()

  const [sastScans, setSastScans] = useState([])
  const [onLoading, setOnLoading] = useState(false);

  const [pageCount, setPageCount] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRecords, setTotalRecords] = useState(100);
  const [onDeleting, setOnDeleting] = useState(false);

  const [scanToDelete, setScanToDelete] = useState(null);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);

  const [itemOffset, setItemOffset] = useState(0);


  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const customStyles = {
    content: {
      top: '30%',
      left: '25%',
      width: '50%',
      right: 'auto',
      bottom: 'auto',
      height: '15%',
      backgroundColor: '#ffffff',
      borderRadius: 15,
      borderColor: 'ffffff'
    },
  };

  

  // Function to handle the button click and show the confirm dialog
  const handleClick = (user) => {
    setScanToDelete(user);
    setModalIsOpen(true);
  };

  // Function to handle the confirmation action
  const handleConfirmation = (confirmed) => {

    if (confirmed) {
      // Call the function with the value passed to this component
      deleteFunction(scanToDelete);
    }
    setModalIsOpen(false);
  };

  // Function to delete the scan
  const deleteFunction = (scan) => {
    deleteScan(scan)
  };

  const closeModal = async () => {

    setModalIsOpen(false);
  };


  const deleteScan = async (id) => {

    // Construct the request body
    const requestBody = {
      id: id
    };

    // Retrieve the bearer token from localStorage
    const bearerToken = localStorage.getItem('ASIToken');

    try {

      // Make the API request
      const response = await axios.post('api/v1/sastScans/deleteSastScan', requestBody, {
        headers: {
          Authorization: `Bearer ${bearerToken}`,
        },
      });

      // Handle the API response
      setOnDeleting(false);


      if (response.data.hasOwnProperty('error')) {

        toast.error(response.data.error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

        setOnDeleting(false);

      } else {

        toast('Scan deleted', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

       // window.location.reload();
       fetchSASTScans(true, page, rowsPerPage);

       setOnDeleting(false);

      }


    } catch (error) {
      // Handle API error
      console.error('Error:', error);
      setOnDeleting(false)
    }

  }


  const toaster = useRef()
  const exampleToast = (
    <CToast>
      <CToastBody>Success</CToastBody>
    </CToast>
  )


  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  const isFirstTime = useRef(true);


  const fetchSASTScans = async (isFirstTime, page, rowsPerPage) => {

    if (isFirstTime) {
      setOnLoading(true);
    }
  
    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/sastScans/getAllSastScans/${page}/${rowsPerPage}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  
    setSastScans(response.data.sastScans);
    setCount(response.data.totalCount);      
  
    setOnLoading(false);
  };



  useEffect(() => {


    const interval = setInterval(() => {

      fetchSASTScans(false, page, rowsPerPage);

    }, 20000);
    

    // Make the initial call immediately
    fetchSASTScans(true, 0, rowsPerPage);
    isFirstTime.current = false;

    // Clean up the interval on component unmount
    return () => clearInterval(interval);

  }, []);


  const [user, setUser] = useState({});
  
  useEffect(() => {

    getUserDetails();

  }, []);

  const getUserDetails = () => {
    

    setUser(JSON.parse(localStorage.getItem('ASIUser')));
  }

  const goToViewReport = async (scanId) => {

    navigate('/sast-scan-detail?scanId=' + scanId);
  };

  console.log('user.role:', user.role)


  const columns = [
    {
      name: 'ID',
      label: 'ID',
      options: {
          filter:false,
          sort: true,
          sortDirection: 'desc', // Set default sort direction to descending
      }
    },
    {
      label: "Scan Name",
      options: {
        filter: false,
        download: true,
      }
    },   
    "Application",
    {
      label: "Files Scanned",
      options: {
        filter: false,
        download: true,
      }
    },   
    {
      label: "Issue Count",
      options: {
        filter: false,
        download: true,
      }
    },   
    {
      label: "Started At",
      options: {
        filter: false,
        download: true,
      }
    },   
    {
      label: "Completed At",
      options: {
        filter: false,
        download: true,
      }
    },   
    {
      label: "Status",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "center"
            }} >

              {value.scanCompletedAt ?
                <span style={{ fontSize: 12, color: '#fff', fontWeight: 'normal', backgroundColor:'#195905', padding:5, paddingTop:10,borderRadius:5 }}>COMPLETED</span>
                :
                <text style={{ fontSize: 12, color: '#000', fontWeight: 'normal', backgroundColor:'#ffbf00', padding:5, paddingTop:10,borderRadius:5 }}>PROCESSING</text>
              }

            </div>
          )
        }
      }
    },
    {
      label: "View",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "center"
            }} >

              {(value.scanCompletedAt) ?
                <CButton color="primary" variant="outline"
                  onClick={() => goToViewReport(value._id)}
                  className="m-2" style={{ width: '100%', fontSize: 12, fontWeight: 'bold', color: '#fff', 
                  borderColor:'#fff', display:'flex', flexDirection:'row', alignItems:'center' }}>
                    <FaEye style={{color:'white', marginRight:5}} size={15}/>
                View Report</CButton>
                :
                <span style={{ fontSize: 15, color: '#fff', fontWeight: 'bold' }}>---</span>
              }

            </div>
          )
        }
      }
    },
    {
      label: "Actions",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (<
            div style={{
              display: "flex",
              alignItems: "center"
            }
            } >

            <>

            {(user.role == "Administrator" || user.role == "Application Manager") &&

            <CButton color="danger"
              onClick={() => handleClick(value)}
              variant="outline"
              className="m-1"
              style={{ width: '100%', fontSize: 12, fontWeight: 'bold', color:'red', borderColor:'red', display:'flex', flexDirection:'row',
               alignItems:'center', justifyContent:'center' }}>
              
              <MdDeleteOutline style={{color:'red', marginRight:5}} size={15}/>Delete</CButton>
            }

            </>
          </div>
          )
        }
      }
    },

  ];

  const getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            '&:nth-child(1)': {
              width: 30,
            },
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            '&:nth-child(1)': {
              width: 30,
            },
          }
        }
      },

    }
  })


  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    elevation: 0, //for table shadow box
    filter: true,
    download: true,
    print: true,
    search: true,
    searchOpen: true,
    viewColumns: true,
    selectableRows: false, // <===== will turn off checkboxes in rows
    rowsPerPageOptions: [10, 20, 60, 100, 150],
    pagination: true,
    textLabels: {
      body: {
        noMatch: 'No scans created yet',
      }
    },
    sortOrder: {
      name: 'ID',
      direction: 'desc' // Default sorting on ID column
    },
    serverSide: true,
    count: count,
    page: page,
    rowsPerPage: rowsPerPage,
    onTableChange: (action, tableState) => {
      if (action === 'changePage' || action === 'changeRowsPerPage') {

        const { page, rowsPerPage } = tableState;

        
        fetchSASTScans(true, page, rowsPerPage);
        setPage(page);
        setRowsPerPage(rowsPerPage);
      }
    }
  };


  var tableData = [];

  //sastScans.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

  for (var i = 0; i < sastScans.length; i++) {

    var dataItem = [];

    console.log('page:', page)

    dataItem.push(((page) * 10) + (i+1));
    dataItem.push(sastScans[i].scanName);
    dataItem.push(sastScans[i].application.name);
    
    dataItem.push(sastScans[i].filesScanned?sastScans[i].filesScanned.length:0);
    dataItem.push(sastScans[i].issueCount);

    dataItem.push((new Date(sastScans[i].createdAt)).toLocaleDateString('en-US') + ' - ' + (new Date(sastScans[i].createdAt)).toLocaleTimeString('en-US'));

    if (sastScans[i].scanCompletedAt) {
      dataItem.push((new Date(sastScans[i].scanCompletedAt)).toLocaleDateString('en-US')
        + ' - ' + (new Date(sastScans[i].scanCompletedAt)).toLocaleTimeString('en-US'));
    } else {
      dataItem.push('---');
    }


    dataItem.push(sastScans[i]); // for status
    dataItem.push(sastScans[i]); // for view report link
    dataItem.push(sastScans[i]._id); // for delete

    tableData.push(dataItem);
  }

  const goToStartQuickScan = (e) => {

    e.preventDefault();
    navigate('/start-sast-scan')
  }



  return (
    <div className="activeScans">

      {setModalIsOpen && (
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Remediations"
        >
          <text style={{ color: '#000', fontSize: 18 }}>Are you sure you want to permanently delete this scan?</text>
          <br/><br/>
          <button onClick={() => handleConfirmation(true)} style={{ width: 100, borderWidth: 0, backgroundColor: 'green', color:'white', padding: 10 }}>Yes</button>
          <button onClick={() => handleConfirmation(false)} style={{ marginLeft: 30, borderWidth: 0, width: 100, backgroundColor: 'red', color:'white', padding: 10 }}>No</button>
        </Modal>
      )}


      <div style={{ width: '100%', overflowX:'hidden' }}>
        <div>
          <div style={{ marginBottom: '2rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>

            <h2>SAST Scans</h2>



            <CButton
              style={{
                width: 300,
                marginBottom: '2%',
                borderWidth: 0,
                fontSize: 20,
                background: '#e50202'
              }}
              onClick={goToStartQuickScan}
              color="primary"
              className="px-3"
            >
              <AiOutlineSecurityScan size={25} color='white'/>
              <span style={{marginLeft:10}}>Start a SAST Scan</span>
            </CButton>
          </div>

          {onLoading &&
            <ShimmerTable row={8} col={10} />
          }


          {!onLoading &&

            <>

              <ThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                  style={{ height: "57vh" }}
                  data={tableData}
                  columns={columns}
                  options={options}
                />
              </ThemeProvider>


            
            </>
          }

        </div>
      </div>
    </div>
  )
}

export default QuickScans



