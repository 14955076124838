import React from 'react';

const TravisCIIntegration = () => {
  return (
    <div className="p-4">
      <h3 className="darkText">Travis CI Integration Instructions for SAST and SCA Scans</h3>
      <hr className="mb-4"/>

      <h4 className="darkText">Step 1: Configure Travis CI</h4>
      <p className="mb-4">Ensure you have set up the following environment variables in your Travis CI project settings:</p>
      <ul className="list-disc pl-5 mb-4">
        <li>INTRUCEPT_CLIENT_ID</li>
        <li>INTRUCEPT_CLIENT_SECRET</li>
        <li>INTRUCEPT_APPLICATION_ID</li>
      </ul>

      <h4 className="darkText">Step 2: Create .travis.yml File</h4>
      <p className="mb-4">Create a <code className="bg-gray-200 p-1 rounded">.travis.yml</code> file in your project root with the following content:</p>
      <pre className="bg-gray-100 p-4 rounded overflow-x-auto mb-4">
        <code>
{`language: node_js
node_js:
  - "14"

jobs:
  include:
    - stage: SAST Check
      script:
        - zip -r project.zip . -x "*.git*" "*/node_modules/*" "*/vendor/*" "*.zip"
        - |
          RESPONSE=$(curl -X POST \
            -H "Client-ID: $INTRUCEPT_CLIENT_ID" \
            -H "Client-Secret: $INTRUCEPT_CLIENT_SECRET" \
            -F "projectZipFile=@project.zip" \
            -F "applicationId=$INTRUCEPT_APPLICATION_ID" \
            -F "scanName=SAST Scan - $TRAVIS_COMMIT" \
            -F "language=javascript" \
            https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSASTScan)
          
          CAN_PROCEED=$(echo $RESPONSE | jq -r '.canProceed')
          VULNS_TABLE=$(echo $RESPONSE | jq -r '.vulnsTable')
          echo "Vulnerabilities Table:"
          echo "$VULNS_TABLE"
          
          if [ "$CAN_PROCEED" != "true" ]; then
            echo "SAST scan failed. Please review the security issues."
            exit 1
          fi

    - stage: SCA Check
      script:
        - zip -r project.zip . -x "*.git*" "*/node_modules/*" "*/vendor/*" "*.zip"
        - |
          RESPONSE=$(curl -X POST \
            -H "Client-ID: $INTRUCEPT_CLIENT_ID" \
            -H "Client-Secret: $INTRUCEPT_CLIENT_SECRET" \
            -F "projectZipFile=@project.zip" \
            -F "applicationId=$INTRUCEPT_APPLICATION_ID" \
            -F "scanName=SCA Scan - $TRAVIS_COMMIT" \
            -F "language=javascript" \
            https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSCAScan)
          
          CAN_PROCEED=$(echo $RESPONSE | jq -r '.canProceed')
          VULNS_TABLE=$(echo $RESPONSE | jq -r '.vulnsTable')
          echo "Vulnerabilities Table:"
          echo "$VULNS_TABLE"
          
          if [ "$CAN_PROCEED" != "true" ]; then
            echo "SCA scan failed. Please review the dependency issues."
            exit 1
          fi`}
        </code>
      </pre>

      <h4 className="darkText">Step 3: Customize Configuration</h4>
      <p className="mb-4">Adjust the following in the <code className="bg-gray-200 p-1 rounded">.travis.yml</code> file as needed:</p>
      <ul className="list-disc pl-5 mb-4">
        <li>Node.js version</li>
        <li>Language setting (currently set to "javascript")</li>
        <li>Any project-specific exclusions in the zip command</li>
      </ul>

      <h4 className="darkText">Step 4: Commit and Push</h4>
      <p className="mb-4">Commit the <code className="bg-gray-200 p-1 rounded">.travis.yml</code> file to your repository and push the changes. This will trigger the Travis CI build with SAST and SCA checks.</p>

      <h4 className="darkText">Step 5: Monitor Build Results</h4>
      <p className="mb-4">Check the Travis CI build logs to see the results of the SAST and SCA scans. The build will fail if either scan detects issues that prevent it from proceeding.</p>

      <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
        <p className="font-bold">Note:</p>
        <p>Ensure that you have the necessary permissions and have configured your Travis CI environment variables correctly. The exact configuration may vary based on your specific project requirements and Travis CI setup.</p>
      </div>
    </div>
  );
};

export default TravisCIIntegration;