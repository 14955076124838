import React from 'react';

const EclipseIntegration = () => {
  return (
    <div className="eclipse-integration-guide">
      <h2 className="darkText">Eclipse Integration for SAST and SCA Scans</h2>
      <hr/>

      <section>
        <h3 className="darkText">Prerequisites</h3>
        <ul>
          <li>Ensure Python 3 is installed on your system and accessible via the command line.</li>
          <li>The Intrucept scan script (intrucept-scan.py) should be available on your system.</li>
          <li>Your project should have an 'intrucept-config.txt' file in its root directory with the necessary credentials (see Step 1).</li>
        </ul>
      </section>

      <section>
        <h3 className="darkText">Step 1: Set Up intrucept-config.txt</h3>
        <ol>
          <li>In your project's root directory, create a file named <code>intrucept-config.txt</code>.</li>
          <li>Add the following content to the file:
            <pre>
              <code>
{`CLIENT_ID = <your_intrucept_client_id>
CLIENT_SECRET = <your_intrucept_client_secret>
APPLICATION_ID = <your_intrucept_appliation_id>`}
              </code>
            </pre>
          </li>
          <li>Replace the values with your actual Intrucept credentials.</li>
          <li>Save the file.</li>
        </ol>
        <p><strong>Note:</strong> Keep this file secure and do not commit it to version control.</p>
      </section>

      <section>
        <h3 className="darkText">Step 2: Configure External Tools in Eclipse</h3>
        <ol>
          <li>Open Eclipse IDE.</li>
          <li>Go to <strong>Run</strong> {'>'} <strong>External Tools</strong> {'>'} <strong>External Tools Configurations</strong>.</li>
          <li>In the left panel, right-click on <strong>Program</strong> and select <strong>New Configuration</strong>.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Step 3: Set Up SAST Scan External Tool</h3>
        <ol>
          <li>Name the configuration "Perform SAST Scan".</li>
          <li>In the <strong>Main</strong> tab, set the following:
            <ul>
              <li><strong>Location:</strong> /usr/bin/python3</li>
              <li><strong>Working Directory:</strong> {"${project_loc}"} (This will use the current project's directory)</li>
              <li><strong>Arguments:</strong> /path/to/intrucept-scan.py SAST {"${project_loc}"}</li>
            </ul>
          </li>
          <li>Click <strong>Apply</strong> to save the configuration.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Step 4: Set Up SCA Scan External Tool</h3>
        <ol>
          <li>Create another new configuration and name it "Perform SCA Scan".</li>
          <li>In the <strong>Main</strong> tab, set the following:
            <ul>
              <li><strong>Location:</strong> /usr/bin/python3</li>
              <li><strong>Working Directory:</strong> {"${project_loc}"} (This will use the current project's directory)</li>
              <li><strong>Arguments:</strong> /path/to/intrucept-scan.py SCA {"${project_loc}"}</li>
            </ul>
          </li>
          <li>Click <strong>Apply</strong> to save the configuration.</li>
        </ol>
      </section>

      <section>
        <h3 className="darkText">Step 5: Running the Scans</h3>
        <ol>
          <li>To run a scan, go to <strong>Run</strong> {'>'} <strong>External Tools</strong> {'>'} <strong>Perform SAST Scan</strong> or <strong>Perform SCA Scan</strong>.</li>
          <li>The scan will start, and the output will be displayed in the Eclipse Console.</li>
        </ol>
      </section>

     
    </div>
  );
};

export default EclipseIntegration;