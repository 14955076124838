import React, { useState, useEffect, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
  CFormLabel,
  CFormSelect,
  CTextarea
} from '@coreui/react'
import { useParams, useNavigate } from 'react-router-dom'
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { validateEntityName } from '../../../../helpers/validation';

const AddBusinessUnit = () => {
  const navigate = useNavigate()

  const [businessUnitName, setBusinessUnitName] = useState("")
  const [loading, setLoading] = useState(false) 
  const [error, setError] = useState('');
  const toaster = useRef(); 
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const validateForm = () => {
    if (!validateEntityName(businessUnitName)) {
      setError('Invalid business unit name. Only letters, numbers, spaces, underscores, dots, #, @ and hyphens are allowed (max 100 characters).');
      return false;
    }
    setError('');
    return true;
  };

  const addBusinessUnit = () => {
    if (validateForm()) {
      setLoading(true)

      const bearerToken = localStorage.getItem('ASIToken');

      const requestData = {
        name: businessUnitName,
      };

      fetch(global.backendUrl + '/api/v1/organizations/addBusinessUnit', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      })
        .then(response => response.json())
        .then(data => {
          if (data.hasOwnProperty('error')) {
            setError(data.error);
            setLoading(false);
          }
          else if (data.hasOwnProperty('err')) {
            setLoading(false);
            setError("Something went wrong. Please try again");
          } else {
            setSubmissionSuccess(true);
            setLoading(false);

            toast('Business unit added', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });

            navigate('/business-units')
          }
        })
        .catch(error => {
          console.error(error);
          setLoading(false);
          setError("An error occurred. Please try again.");
        });      
    }
  }

  const goBack = (e) => {
    e.preventDefault();
    navigate('/business-units')
  }

  return (
    <div style={{ display: 'flex', overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>
      <div style={{ width: '60%' }}>
        <div>
          <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h2>Add Business Unit</h2>
            <CButton
              onClick={goBack}
              style={{
                width: 300,
                marginBottom: '2%',
                marginRight: 20,
                borderWidth: 0,
                fontSize: 20,
                borderColor: '#fff',
                borderWidth: 1,
                color: '#fff',
                background: 'transparent'
              }}
              color="primary"
              className="px-3"
            >
              <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight: 10 }} />
              Back to Business Units
            </CButton>
          </div>

          <div style={{ width: '100%', backgroundColor: '#252B3B', padding: 15 }}>
            <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Business Unit Name</CFormLabel>
            <CInputGroup className="" style={{ flexDirection: 'column' }}>
              <CFormInput
                placeholder="Business Unit Name"
                autoComplete="businessUnitName"
                className="white-input"
                onChange={(e) => setBusinessUnitName(e.target.value)}
                style={{ width: '100%' }}
                maxLength={100}
              />
              {error && <div style={{ color: 'red', marginTop: '5px' }}>{error}</div>}
            </CInputGroup>

            <CButton
              style={{
                width: '100%',
                marginTop: '3%',
                marginBottom: '2%',
                borderWidth: 0,
                fontSize: 20,
                background: '#e50202'
              }}
              color="primary"
              className="px-3"
              onClick={addBusinessUnit}
              disabled={loading}
            >
              {loading ?
                <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                :
                'Save Business Unit'
              }
            </CButton>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default AddBusinessUnit