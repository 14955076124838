import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CButton, CFormSelect, CTable, CToast, CToastBody, CToaster } from '@coreui/react'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useLocation } from 'react-router-dom'
import { useParams, useNavigate } from 'react-router-dom'

import axios from 'axios';


import { CSSProperties } from "react";
import GridLoader from "react-spinners/GridLoader";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import Modal from 'react-modal';
import { AiFillCloseCircle } from "react-icons/ai";
import zIndex from "@mui/material/styles/zIndex";
import { borderColor } from "@mui/system";
import { CircularProgress, Menu, MenuItem } from '@mui/material';

import { IoMdArrowRoundBack } from "react-icons/io";
import { BiExport } from "react-icons/bi";
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";
import { CgNotes } from "react-icons/cg";
import { FaCaretDown } from "react-icons/fa";

import Chart from 'react-apexcharts'
import CustomReportModal from './CustomReportModal';

const ScanDetails = () => {

  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);

  const [toast, addToast] = useState(0)
  const navigate = useNavigate()
  const [scanId, setScanId] = useState('')
  const [userId, setUserId] = useState('')
  const [scaScan, setScaScan] = useState(null)
  const [onLoading, setOnLoading] = useState(true);
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [currentVulnerability, setCurrentVulnerability] = React.useState(null);

  const [exportingPDF, setExportingPDF] = useState(false);
  const [customReportModalOpen, setCustomReportModalOpen] = useState(false);

  const toaster = useRef()
  const exampleToast = (
    <CToast>
      <CToastBody>Success</CToastBody>
    </CToast>
  )

  const customStyles = {
    content: {
      top: '20%',
      left: '20%',
      width: '60%',
      right: 'auto',
      bottom: 'auto',
      height: '70%',
      backgroundColor: '#E1E1E1',
      borderRadius: 15,
      borderColor: 'yellow',
      zIndex: 1000,
    },
  };

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };


  ChartJS.register(ArcElement, Tooltip, Legend);

  useEffect(() => {

    window.scrollTo(0, 0);

    setOnLoading(true);

    var arr = location.search.split('=');

    var theScanId = arr[1];

    setScanId(theScanId);

    loadScanDetails(theScanId);

    var user = JSON.parse(localStorage.getItem('ASIUser'));
    setUserId(user._id)


  }, []);

  useEffect(() => {
    // console.log('onLoading', onLoading)

  }, [onLoading]);


  const loadScanDetails = async (theScanId) => {

    setOnLoading(true);

    const data = {
      scanId: theScanId,
    };

    const token = localStorage.getItem('ASIToken');
    const response = await axios.get('api/v1/scaScans/getScaScanDetails/' + theScanId, {
      headers: { Authorization: `Bearer ${token}` },
    });

    setScaScan(response.data.scaScan);

    setOnLoading(false);

  };


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (action) => {
    setExportingPDF(true);
    switch (action) {
      case 'summary':
        downloadSummaryReport();
        break;
      case 'detailed':
        downloadDetailedReport();
        break;
      case 'custom':
        downloadCustomReport();
        break;
      case 'xml':
        exportToXML();
        break;
      case 'json':
        exportToJSON();
        break;
      default:
        break;
    }
    setExportingPDF(false);
    handleClose();
  };

  const downloadSummaryReport = () => {

    const urlToOpen = global.reportAPIURL + '/api/v1/download/sca-report-download/' + scanId + '/' + userId + '/summary/none';
    window.open(urlToOpen, '_blank');
  };

  const downloadDetailedReport = () => {
    downloadPDF();
  };

  const downloadCustomReport = () => {
    setCustomReportModalOpen(true);
  };

  const exportToXML = () => {

    console.log('Exporting to XML');

    const jsonToXml = (obj, rootName) => {
      let xml = '';
      if (rootName) {
        xml += `<${rootName}>`;
      }

      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          xml += `<${key}>`;
          for (const item of obj[key]) {
            if (typeof item === 'object') {
              xml += jsonToXml(item, 'item');
            } else {
              xml += `<item>${item}</item>`;
            }
          }
          xml += `</${key}>`;
        } else if (typeof obj[key] === 'object' && obj[key] !== null) {
          xml += jsonToXml(obj[key], key);
        } else {
          xml += `<${key}>${obj[key]}</${key}>`;
        }
      }

      if (rootName) {
        xml += `</${rootName}>`;
      }
      return xml;
    };

    const formatXml = (xml) => {
      const PADDING = ' '.repeat(2); // Indentation level
      const reg = /(>)(<)(\/*)/g;
      let pad = 0;
      xml = xml.replace(reg, '$1\r\n$2$3');
      return xml.split('\r\n').map((node) => {
        let indent = 0;
        if (node.match(/.+<\/\w[^>]*>$/)) {
          indent = 0;
        } else if (node.match(/^<\/\w/)) {
          if (pad !== 0) {
            pad -= 1;
          }
        } else if (node.match(/^<\w([^>]*[^\/])?>.*$/)) {
          indent = 1;
        } else {
          indent = 0;
        }

        const padding = PADDING.repeat(pad);
        pad += indent;
        return padding + node;
      }).join('\r\n');
    };

    const xmlString = jsonToXml(scaScan, 'SCAScan');
    const formattedXml = formatXml(xmlString);
    const blob = new Blob([formattedXml], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'scaScan.xml';
    link.click();
    URL.revokeObjectURL(url); // Clean up the URL object

  };

  const exportToJSON = () => {

    console.log('Exporting to JSON');

    const jsonString = JSON.stringify(scaScan, null, 2);
    const blob = new Blob([jsonString], { type: 'application/json' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'SCA_Scan_Report_' + scaScan._id + '.json';
    link.click();
    URL.revokeObjectURL(url);

  };


  const handleGenerateCustomReport = (selectedOptions) => {

    console.log('Generating Custom Report with options:', selectedOptions);
    const optionsString = JSON.stringify(selectedOptions);
    const urlToOpen = global.reportAPIURL + '/api/v1/download/sca-report-download/' + scanId + '/' + userId + '/custom/' + optionsString;
    window.open(urlToOpen, '_blank');
  }; 


  
  const downloadPDF = async () => {

    const urlToOpen = global.reportAPIURL + '/api/v1/download/sca-report-download/' + scanId + '/' + userId + '/full/none';
    window.open(urlToOpen, '_blank');

  }

  const openRemediationModal = async (value) => {

    setCurrentVulnerability(value);

    setModalIsOpen(true);
  };

  const closeModal = async () => {

    setModalIsOpen(false);
  };

  const goBack = async () => {

    navigate('/sca-scans')
  }

  const columns = [
    {
      label: "#",
      options: {
        filter: false,
        download: true,
      }
    },   
    "Package Name",
    "Issue",
    {
      label: "Reported At",
      options: {
        filter: false,
        download: true,
      }
    },   
    {
      label: "Severity",
      options: {
        filter: true,
        download: true,
        customBodyRender: (value, tableMeta, updateValue) => {

          let bgColor;
          let theColor;

          if (value == 'CRITICAL') {

            bgColor = '#990000';
            theColor = '#fff';

          } else if (value == 'HIGH') {

            bgColor = '#FF6600';
            theColor = '#fff';


          } else if (value == 'MEDIUM') {

            bgColor = '#FFCC00';
            theColor = 'black';

          } else if (value == 'LOW') {

            bgColor = '#669933';
            theColor = '#fff';

          }

          return (
            <div style={{
              display: "flex",
              alignItems: "center"
            }} >

              <text style={{
                padding: 5, backgroundColor: bgColor, color: theColor, width: 120,
                textAlign: 'center', borderRadius: 10, fontSize: 13
              }}>{value}</text>

            </div>
          )
        }
      }
    },
    {
      label: "CWE",
      options: {
        filter: false,
        download: true,
        customBodyRender: (value, tableMeta, updateValue) => {


          if (value !== '---') {

            return (
              <div style={{
                display: "flex",
                alignItems: "center",
                flexDirection: 'column',
              }} >

                {value.map(val => (
                  <text style={{
                    padding: 5, margin: 5, backgroundColor: '#fff', color: '#000', fontWeight: 'bold', width: 120,
                    textAlign: 'center', borderRadius: 10, fontSize: 13
                  }}>{val}</text>
                ))}


              </div>
            )
          } else {
            return (
              <div style={{
                display: "flex",
                alignItems: "center"
              }} >

                ---


              </div>
            )
          }


        }
      }
    },
    {
      label: "CVE",
      options: {
        filter: false,
        download: true,
        customBodyRender: (value, tableMeta, updateValue) => {


          if (value !== '---') {

            return (
              <div style={{
                display: "flex",
                alignItems: "center",
                flexDirection: 'column',
              }} >

                {value.map(val => (
                  <text style={{
                    padding: 5, margin: 5, backgroundColor: '#fff', color: '#000', fontWeight: 'bold', width: 120,
                    textAlign: 'center', borderRadius: 10, fontSize: 13
                  }}>{val}</text>
                ))}


              </div>
            )
          } else {
            return (
              <div style={{
                display: "flex",
                alignItems: "center"
              }} >

                ---


              </div>
            )
          }


        }
      }
    },
    {
      label: "PCI DSS Violations",
      options: {
        filter: false,
        download: true,
        customBodyRender: (value, tableMeta, updateValue) => {


          if (value !== '---') {

            return (
              <div style={{
                display: "flex",
                alignItems: "center",
                flexDirection: 'column',
              }} >

                {value.map(val => (
                  <text style={{
                    padding: 5, margin: 5, backgroundColor: '#fffde7', color: '#000', fontWeight: 'bold', width: 120,
                    textAlign: 'center', borderRadius: 10, fontSize: 13
                  }}>{val}</text>
                ))}


              </div>
            )
          } else {
            return (
              <div style={{
                display: "flex",
                alignItems: "center"
              }} >

                ---


              </div>
            )
          }


        }
      }
    },
    'Severity Type',
    {
      label: "Severity Score",
      options: {
        filter: false,
        download: true,
      }
    },   
    'License',
    {
      label: "Latest Available Version",
      options: {
        filter: false,
        download: true,
      }
    },   
    {
      label: "References",
      options: {
        filter: false,
        download: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <div style={{
              display: "flex",
              alignItems: "center"
            }} >

              <CButton color="primary" variant="outline"
                onClick={() => openRemediationModal(value)}
                className="m-1" style={{ fontSize: 13, borderColor: 'white', color: 'white' }}>View References
              </CButton>

            </div>
          )
        }
      }
    },

  ];

  const getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            '&:nth-child(1)': {
              width: 30,
            },
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            '&:nth-child(1)': {
              width: 30,
            },
          }
        }
      },

    }
  })


  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    elevation: 0, //for table shadow box
    filter: true,
    download: true,
    print: true,
    search: true,
    searchOpen: false,
    viewColumns: true,
    selectableRows: false, // <===== will turn off checkboxes in rows
    rowsPerPageOptions: [10, 20, 60, 100, 150],
    textLabels: {
      body: {
        noMatch: 'No vulnerabilities found',
      }
    }
  };


  var tableData = [];

  if (scaScan && scaScan.vulnerabilities) {

    for (var i = 0; i < scaScan.vulnerabilities.length; i++) {

      var vuln = scaScan.vulnerabilities[i];

      var dataItem = [];

      dataItem.push(i + 1);


      dataItem.push(vuln.packageName);

      dataItem.push(vuln.issueTitle);
      dataItem.push(vuln.reportdAt);

      console.log('vuln.severity:', vuln.severity)

      if (vuln.severity == 'MODERATE') {
        dataItem.push('MEDIUM');
      } else {
        dataItem.push(vuln.severity);
      }

      if (vuln.cwe && vuln.cwe.length > 0) {
        dataItem.push(vuln.cwe);
      } else {
        dataItem.push('---');
      }

      if (vuln.cve && vuln.cve.length > 0) {
        dataItem.push(vuln.cve);
      }
      else {
        dataItem.push('---');
      }

      if (vuln.pciViolations && vuln.pciViolations.length > 0) {
        dataItem.push(vuln.pciViolations);
      }
      else {
        dataItem.push('---');
      }

      dataItem.push(vuln.severityType);
      dataItem.push(vuln.severityScore);

      dataItem.push(vuln.license);
      dataItem.push(vuln.latestVersion);


      dataItem.push(vuln); // for remediations      

      tableData.push(dataItem);

    }
  }


  var lowCount = 0;
  var mediumCount = 0;
  var highCount = 0;
  var criticalCount = 0;


  if (scaScan) {

    for (var i = 0; i < scaScan.vulnerabilities.length; i++) {

      if (scaScan.vulnerabilities[i].severity == 'CRITICAL') {

        criticalCount++;

      } else if (scaScan.vulnerabilities[i].severity == 'HIGH') {

        highCount++;

      } else if (scaScan.vulnerabilities[i].severity == 'MODERATE' || scaScan.vulnerabilities[i].severity == 'MEDIUM') {

        mediumCount++;

      } else if (scaScan.vulnerabilities[i].severity == 'LOW') {

        lowCount++;

      }

    }
  }

  var dataArray = [criticalCount, highCount, mediumCount, lowCount];

  var labelsArray = ['CRITICAL', 'HIGH', 'MEDIUM', 'LOW'];

  const bgColorArray = [
    '#990000', '#FF6600', '#FFCC00', '#669933',
  ];


  const vulnDistrochartOptions = {
    chart: {
      type: 'pie',
      events: {
        dataPointMouseEnter: function(event, chartContext, config) {
          // This prevents the default hover behavior
          event.preventDefault();
        }
      }
    },
    labels: labelsArray,
    colors: bgColorArray,
    legend: {
      position: 'bottom',
      verticalAlign: 'middle',
      onItemHover: {
        highlightDataSeries: false
      }
    },
    

  };

  const vulnDistroChartSeries = dataArray;

  ///Vuln type chart
  var owaspTop10Count = 0;
  var sans25Count = 0;
  var othersCount = 0;

  var sans25CWEs = ['CWE-787', 'CWE-79', 'CWE-89', 'CWE-416', 'CWE-78', 'CWE-20', 'CWE-125', 'CWE-22', 'CWE-352', 'CWE-434', 'CWE-862', 'CWE-476', 'CWE-287',
    'CWE-190', 'CWE-502', 'CWE-77', 'CWE-119', 'CWE-798', 'CWE-918', 'CWE-306', 'CWE-362', 'CWE-269', 'CWE-94', 'CWE-863', 'CWE-276'];


  if (scaScan) {

    for (var i = 0; i < scaScan.vulnerabilities.length; i++) {

      if (scaScan.vulnerabilities[i].owasp && scaScan.vulnerabilities[i].owasp.length > 0) {
        owaspTop10Count = owaspTop10Count + scaScan.vulnerabilities[i].owasp.length;
      }

      if (scaScan.vulnerabilities[i].cwe && scaScan.vulnerabilities[i].cwe.length > 0) {

        // Check if any sans25CWE is present in the whole cwe value
        const hasMatchingCWE = sans25CWEs.some(cwe => scaScan.vulnerabilities[i].cwe.includes(cwe));

        // Increment the count if there is a matching CWE
        if (hasMatchingCWE) {
          sans25Count++;
        }
      } else {
        othersCount++;
      }


    }
  }

  var dataArray1 = [owaspTop10Count, sans25Count, othersCount];

  var labelsArray1 = ['OWASP Top 10', 'SANS 25', 'Others'];

  const bgColorArray1 = [
    '#00BFFF', '#9370DB', '#008080',
  ];





  console.log('onLoading:', onLoading)

  return (
    <div style={{ overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX:'hidden'}}>

      <>

        {onLoading ?

          <div style={{
            width: '80%', marginLeft: '10%', marginRight: '10%', marginTop: '2%'
          }}>

            <ShimmerTable row={5} col={10} />
          </div>

          :

          <div style={{
            width: '100%', marginLeft: '0%', marginRight: '0%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
            marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
          }}>

            <div style={{ marginBottom: '2rem', }}>
              <h2>{scaScan.projectName}</h2>
              <hr />

              <table style={{width:600}}>

                <tr>

                <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                    <span style={{ fontWeight: 'bold', color: '#000' }}>Scan ID </span>
                  </td>
                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>

                    {scanId}
                  </td>
                </tr>

                <tr>

                <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                  <h6 style={{ fontWeight: 'bold', color: '#000' }}>Scan Name </h6>
                  </td>
                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>

                    {scaScan.scanName}
                  </td>
                </tr>


                <tr>

                <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>
                    <span style={{ fontWeight: 'bold', color: '#000' }}>Application Name </span>
                  </td>
                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>

                    {scaScan.application.name}

                  </td>
                </tr>




                <tr>

                <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>

                    <span style={{ fontWeight: 'bold', color: '#000' }}>Vulnerabilities</span>

                  </td>
                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>

                    {scaScan.vulnerabilities.length}

                  </td>
                </tr>


                <tr>

                <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>

                    <span style={{ fontWeight: 'bold', color: '#000' }}>Scan Started At</span>

                  </td>
                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff' }}>

                    {(new Date(scaScan.createdAt)).toLocaleDateString('en-US')} - {(new Date(scaScan.createdAt)).toLocaleTimeString('en-US')}

                  </td>
                </tr>

                <tr>

                <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>

                    <span style={{ fontWeight: 'bold', color: '#000' }}>Scan Status</span>

                  </td>
                  <td style={{ padding: 10, borderWidth: 0.5, borderColor: '#fff' }}>

                  {scaScan.status ?scaScan.status.toUpperCase():'---'}

                  </td>
                </tr>


                <tr>

                <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', background: '#fff' }}>

                    <span style={{ fontWeight: 'bold', color: '#000' }}>Scan Completed At</span>
                  </td>
                  <td style={{ padding: 10, borderWidth: 1, borderColor: '#fff', width: 400 }}>

                    {(new Date(scaScan.scanCompletedAt)).toLocaleDateString('en-US')} - {(new Date(scaScan.scanCompletedAt)).toLocaleTimeString('en-US')}

                  </td>
                </tr>

              </table>


            </div>

            <div style={{ display: 'flex', flexDirection: 'column' }}>

              <CButton
                onClick={goBack}
                style={{
                  width: 300,
                  marginBottom: '2%',
                  borderWidth: 0,
                  fontSize: 20,
                  borderColor: '#ffffff',
                  borderWidth: 1,
                  color: '#ffffff',
                  background: 'transparent'
                }}
                color="primary"
                className="px-3"
              >
                <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight: 10 }} /> Back to Scans List
              </CButton>

              <CButton
                  onClick={handleClick}
                  style={{
                    width: 300,
                    marginBottom: '2%',
                    borderWidth: 0,
                    fontSize: 20,
                    marginTop: 10,
                    background: '#e50202'
                  }}
                  color="primary"
                  className="px-3"
                >

                  {exportingPDF ?
                    <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                    :
                    <>

                      Download Report <FaCaretDown size={25} style={{ color: '#ffffff', marginLeft: 10 }} />
                    </>
                  }

                </CButton>

                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                  style={{ width: '100%', color: 'white' }}
                >
                  <MenuItem onClick={() => handleMenuItemClick('summary')} style={{ width: '100%', color: 'white' }}>Summary Report (PDF)</MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick('detailed')} style={{ width: '100%', color: 'white' }}>Detailed Report (PDF)</MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick('custom')} style={{ width: '100%', color: 'white' }}>Custom Report (PDF)</MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick('xml')} style={{ width: '100%', color: 'white' }}>Export to XML</MenuItem>
                  <MenuItem onClick={() => handleMenuItemClick('json')} style={{ width: '100%', color: 'white' }}>Export to JSON</MenuItem>
                </Menu>



            </div>
          </div>

        }





        <div style={{
          width: '100%', marginLeft: '0%', marginRight: '0%', display: 'flex', flexDirection: 'column',
          marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
        }}>



          <h4 style={{ marginTop: 30 }}>Severity Scoring</h4>
          <hr />

          <span><text style={{ fontWeight: 'bold', backgroundColor: '#990000', color: '#fff', padding: 5, borderRadius: 5, marginRight: 20 }}>CRITICAL</text> Vulnerabilities that can be exploited remotely, leading to immediate and widespread impact on the confidentiality,
            integrity, and availability of systems or data.</span>

          <span style={{ marginTop: 15 }}><text style={{ fontWeight: 'bold', backgroundColor: '#FF6600', color: '#fff', padding: 5, borderRadius: 5, marginRight: 20 }}>HIGH</text> Vulnerabilities that can be exploited but require some form of user interaction or other
            preconditions to be met, potentially resulting in significant impact on system or data.</span>

          <span style={{ marginTop: 15 }}><text style={{ fontWeight: 'bold', backgroundColor: '#FFCC00', color: '#000', padding: 5, borderRadius: 5, marginRight: 20 }}>MEDIUM</text> Vulnerabilities that could result in a compromise of system or data security,
            but require more complex exploitation techniques or have limited impact.</span>

          <span style={{ marginTop: 15 }}><span style={{ fontWeight: 'bold', backgroundColor: '#669933', color: '#fff', padding: 5, borderRadius: 5, marginRight: 20 }}>LOW</span> Vulnerabilities that have a low likelihood of being exploited or have minimal impact on system or data security.</span>






        </div>



        {onLoading == true ?

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', marginTop: 50 }}>

            <ShimmerTable row={8} col={10} />


          </div>
          :

          <>

         {scaScan.vulnerabilities.length > 0 &&
            <div style={{
              width: '33%', marginLeft: '33%', marginRight: '33%', display: 'flex', flexDirection: 'column',
              marginTop: '2%', background: 'linear-gradient(135deg, #161c2a, #1a2130)', padding: 20, borderRadius: 15
            }}>

              <h4 style={{ color: '#fff', textAlign: 'center' }}>Vulnerability Distribution</h4>
              <hr />

              <Chart options={vulnDistrochartOptions} series={vulnDistroChartSeries} type="pie" />

            </div>
          }

          </>

        }


        <div style={{
          width: '100%', marginLeft: '0%', marginRight: '0%', display: 'flex', flexDirection: 'column',
          marginTop: '2%'
        }}>

          {onLoading &&
            <ShimmerTable row={8} col={10} />

          }

          {!onLoading &&

            <>

              {tableData.length > 0 &&

                <ThemeProvider theme={getMuiTheme()}>
                  <MUIDataTable
                    style={{ height: "57vh" }}
                    data={tableData}
                    columns={columns}
                    options={options}
                  />
                </ThemeProvider>
              }

            </>
          }

        </div>


        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="References"
        >

          <button style={{ float: 'right', backgroundColor: 'transparent', borderWidth: 0 }} onClick={closeModal} >
            <AiFillCloseCircle size={30} color={'#000'} />
          </button>

          {currentVulnerability &&

            <div className="modalWindow" style={{ backgroundColor: '#E1E1E1' }}>


              <h5 style={{ color: '#000' }}><strong>Issue</strong>: {currentVulnerability.issueTitle}</h5>
              <h5 style={{ color: '#000' }}><strong>Severity</strong>: {currentVulnerability.severity == 'MODERATE' ? 'MEDIUM' : currentVulnerability.severity}</h5>

              <hr />

              <h5 style={{ color: '#000' }}>References</h5>
              <hr />

              <div style={{ backgroundColor: '#ebedef', padding: 10, marginTop: 10, borderRadius: 15 }}>

                {currentVulnerability.sources && currentVulnerability.sources.length > 0 && (
                  <div>
                    <h6 style={{ color: '#333', fontSize: 14, fontWeight: 'normal', marginTop: 5 }}>Sources:</h6>
                    <ul style={{ listStyleType: 'none', margin: 0, padding: 0 }}>
                      {currentVulnerability.sources.map((source, index) => (
                        <li key={index} style={{ marginBottom: 5 }}>
                          <a target="_blank" href={source} style={{ color: 'blue' }}> {source}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

              </div>

            </div>
          }


        </Modal>

        <CustomReportModal
          open={customReportModalOpen}
          onClose={() => setCustomReportModalOpen(false)}
          onGenerateReport={handleGenerateCustomReport}
        />

      </>

    </div>
  )
}

export default ScanDetails



