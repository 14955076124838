import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';

import {
  CCol,
  CRow,
  CContainer,
} from '@coreui/react'

import { Link } from "react-router-dom";

import './style.css'; // Import your CSS file

import { HiOutlineDesktopComputer } from "react-icons/hi";
import { TbApi } from "react-icons/tb";
import { AiOutlineIssuesClose } from "react-icons/ai";
import { FiAlertCircle } from "react-icons/fi";
import { MdOutlinePersonalInjury } from "react-icons/md";
import { AiOutlineCloudServer } from "react-icons/ai";

import { BsCollection } from "react-icons/bs";

import { Shimmer, Breathing } from 'react-shimmer'
import { FaUsers } from "react-icons/fa";
import { MdApps } from "react-icons/md";
import { FaObjectGroup } from "react-icons/fa";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { MdErrorOutline } from "react-icons/md";
import { SiHelpdesk } from "react-icons/si";
import { IoIosBusiness } from "react-icons/io";
import { MdOutlineApps } from "react-icons/md";
import { MdOutlineFindInPage } from "react-icons/md";
import { AiFillFolderOpen } from "react-icons/ai";

import { CiBoxList } from "react-icons/ci";
import { CiViewList } from "react-icons/ci";
import { FaListUl } from "react-icons/fa";

import { CgWebsite } from "react-icons/cg";
import { FaMobileAlt } from "react-icons/fa";
import { CiDesktop } from "react-icons/ci";
import { MdMiscellaneousServices } from "react-icons/md";

import Chart from 'react-apexcharts';
import Application from "./organization/applications/application";
import ApplicationFindingsByType from "./dashboardComponents/applicationFindingsByType";
import Counters from "./dashboardComponents/counters";
import Applications from "./dashboardComponents/applications";
import TopOpenApplicationFindings from "./dashboardComponents/topOpenApplicationFindings";
import Top10VulnerableApplications from "./dashboardComponents/top10VulnerableApplications";
import UniqueFindingDistributionBySeverity from "./dashboardComponents/uniqueFindingDistributionBySeverity";
import CWETop25MostDangerous from "./dashboardComponents/cweTop25MostDangerous";
import OWASPDistribution from "./dashboardComponents/owaspDistribution";
import ApplicationFindingsDiscoveredVsResolved from "./dashboardComponents/applicationFindingsDiscoveredVsResolved";
import Top10UniqueApplicationFindings from "./dashboardComponents/top10UniqueApplicationFindings";
import VulnerabilityTrends from "./dashboardComponents/vulnerabilityTrends";


const UserDashboard = () => {

  const random = () => Math.round(Math.random() * 100)

  const [loadingStats, setLoadingStats] = useState(false)

  const [dashboardData, setDashboardData] = useState({})
  const [onLoading, setOnLoading] = useState(false)

  const ref = useRef(null);

  return (
    <>

      <CContainer style={{width:'100%'}}>

        <div style={{ display: 'flex',
           flexDirection: 'row', 
           justifyContent: 'space-between', width: '100%', flexWrap: 'wrap' }}>

          <ApplicationFindingsByType />
          <Counters />
          <Applications />
          
          <TopOpenApplicationFindings />          
          <Top10VulnerableApplications />
          <UniqueFindingDistributionBySeverity />

          
          <CWETop25MostDangerous />
          <OWASPDistribution />
          <ApplicationFindingsDiscoveredVsResolved />
          <Top10UniqueApplicationFindings />
          <VulnerabilityTrends />


        </div>


      </CContainer>
    </>
  )
}

export default UserDashboard