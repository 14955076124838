import React from 'react';

const AtlassianBambooIntegration = () => {
  const yamlContent = `---
version: 2
plan:
  project-key: YOUR_PROJECT_KEY
  key: YOUR_PLAN_KEY
  name: Security Scans

variables:
  CLIENT_ID: '\${bamboo.CLIENT_ID}'
  CLIENT_SECRET: '\${bamboo.CLIENT_SECRET}'
  APPLICATION_ID: 'YOUR_APPLICATION_ID'

stages:
  - Security Scans:
      jobs:
        - SAST Scan
        - SCA Scan

SAST Scan:
  tasks:
    - script:
        - |
          zip -r project.zip . -x "*.git*" "*/node_modules/*" "*/vendor/*" "*.zip"
          RESPONSE=$(curl -X POST \\
            -H "Client-ID: $CLIENT_ID" \\
            -H "Client-Secret: $CLIENT_SECRET" \\
            -F "projectZipFile=@project.zip" \\
            -F "applicationId=$APPLICATION_ID" \\
            -F "scanName=SAST Scan - \${bamboo.buildNumber}" \\
            -F "language=javascript" \\
            https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSASTScan)
          
          CAN_PROCEED=$(echo $RESPONSE | jq -r '.canProceed')
          VULNS_TABLE=$(echo $RESPONSE | jq -r '.vulnsTable')
          echo "Vulnerabilities Table:"
          echo "$VULNS_TABLE"
          
          if [ "$CAN_PROCEED" != "true" ]; then
            echo "SAST scan failed. Please review the security issues."
            exit 1
          fi
  final-tasks:
    - script:
        - echo "SAST Scan completed"

SCA Scan:
  tasks:
    - script:
        - |
          zip -r project.zip . -x "*.git*" "*/node_modules/*" "*/vendor/*" "*.zip"
          RESPONSE=$(curl -X POST \\
            -H "Client-ID: $CLIENT_ID" \\
            -H "Client-Secret: $CLIENT_SECRET" \\
            -F "projectZipFile=@project.zip" \\
            -F "applicationId=$APPLICATION_ID" \\
            -F "scanName=SCA Scan - \${bamboo.buildNumber}" \\
            -F "language=javascript" \\
            https://appsecops-api.intruceptlabs.com/api/v1/integrations/performSCAScan)
          
          CAN_PROCEED=$(echo $RESPONSE | jq -r '.canProceed')
          VULNS_TABLE=$(echo $RESPONSE | jq -r '.vulnsTable')
          echo "Vulnerabilities Table:"
          echo "$VULNS_TABLE"
          
          if [ "$CAN_PROCEED" != "true" ]; then
            echo "SCA scan failed. Please review the dependency issues."
            exit 1
          fi
  final-tasks:
    - script:
        - echo "SCA Scan completed"

---
version: 2
deployment:
  name: Security Scan Results
  release-naming: { version-name: v\${bamboo.buildNumber} }
  environments:
    - Development`;

  return (
    <div className="p-4">
      <h3 className="darkText">Atlassian Bamboo Integration Instructions for SAST and SCA Scans</h3>
      <hr className="mb-4"/>

      <h4 className="darkText">Step 1: Configure API Credentials</h4>
      <p className="mb-4">Ensure you have your <strong>Client ID</strong> and <strong>Client Secret</strong> ready. You will use these in your Bamboo plan configuration.</p>

      <h4 className="darkText">Step 2: Create Bamboo YAML Spec File</h4>
      <p className="mb-4">Create a file named <code className="bg-gray-200 p-1 rounded">bamboo-specs.yaml</code> in your project repository with the following content:</p>
      <pre className="bg-gray-100 p-4 rounded overflow-x-auto mb-4">
        <code>{yamlContent}</code>
      </pre>

      <h4 className="darkText">Step 3: Configure Bamboo Plan</h4>
      <ol className="list-decimal pl-5 mb-4">
        <li>Go to your Bamboo server and create a new plan or edit an existing one.</li>
        <li>In the plan configuration, enable "Bamboo Specs".</li>
        <li>Set the "Location" to "Repository" and specify the path to your <code className="bg-gray-200 p-1 rounded">bamboo-specs.yaml</code> file.</li>
        <li>Save the plan configuration.</li>
      </ol>

      <h4 className="darkText">Step 4: Configure Bamboo Variables</h4>
      <ol className="list-decimal pl-5 mb-4">
        <li>Go to your Bamboo plan configuration.</li>
        <li>Navigate to the "Variables" tab.</li>
        <li>Add the following variables:
          <ul className="list-disc pl-5 mt-2">
            <li><strong>CLIENT_ID</strong>: Your Intrucept Client ID</li>
            <li><strong>CLIENT_SECRET</strong>: Your Intrucept Client Secret</li>
          </ul>
        </li>
        <li>Make sure to mark the CLIENT_SECRET as sensitive to keep it secure.</li>
      </ol>

      <h4 className="darkText">Step 5: Customize Configuration</h4>
      <p className="mb-4">In the <code className="bg-gray-200 p-1 rounded">bamboo-specs.yaml</code> file, replace the following placeholders:</p>
      <ul className="list-disc pl-5 mb-4">
        <li><code className="bg-gray-200 p-1 rounded">YOUR_PROJECT_KEY</code>: Your Bamboo project key</li>
        <li><code className="bg-gray-200 p-1 rounded">YOUR_PLAN_KEY</code>: Your Bamboo plan key</li>
        <li><code className="bg-gray-200 p-1 rounded">YOUR_APPLICATION_ID</code>: Your Intrucept Application ID</li>
        <li>Adjust the <code className="bg-gray-200 p-1 rounded">language</code> parameter if your project is not JavaScript-based</li>
      </ul>

      <div className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 p-4 mb-4" role="alert">
        <p className="font-bold">Note:</p>
        <p>Ensure that you have the necessary permissions and have configured your Bamboo environment correctly. The exact configuration may vary based on your specific project requirements and Bamboo setup.</p>
      </div>
    </div>
  );
};

export default AtlassianBambooIntegration;