import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CButton, CFormSelect, CTable, CToast, CToastBody, CToaster } from '@coreui/react'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { CSSProperties } from "react";
import GridLoader from "react-spinners/GridLoader";
import { ShimmerTable } from "react-shimmer-effects";
import Modal from 'react-modal';
import ReactPaginate from 'react-paginate';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { FaEye } from "react-icons/fa";
import { MdDeleteOutline } from "react-icons/md";
import { CiEdit } from "react-icons/ci";

const Tickets = () => {

  const navigate = useNavigate();
  const [tickets, setTickets] = useState([]);
  const [onLoading, setOnLoading] = useState(false);
  const [onDeleting, setOnDeleting] = useState(false);
  const [ticketToDelete, setTicketToDelete] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const customStyles = {
    content: {
      top: '30%',
      left: '25%',
      width: '50%',
      right: 'auto',
      bottom: 'auto',
      height: '15%',
      backgroundColor: '#ffffff',
      borderRadius: 15,
      borderColor: 'ffffff'
    },
  };

  const handleClick = (ticket) => {
    setTicketToDelete(ticket);
    setModalIsOpen(true);
  };

  const handleConfirmation = (confirmed) => {
    if (confirmed) {
      deleteFunction(ticketToDelete);
    }
    setModalIsOpen(false);
  };

  const deleteFunction = (ticket) => {
    deleteTicket(ticket);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const deleteTicket = async (id) => {
    const requestBody = { id: id };
    const bearerToken = localStorage.getItem('ASIToken');

    try {
      setOnDeleting(true);
      const response = await axios.post('api/v1/organizations/deleteTicket', requestBody, {
        headers: { Authorization: `Bearer ${bearerToken}` },
      });

      if (response.data.hasOwnProperty('error')) {

        toast.error(response.data.error, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast('Ticket deleted', {

          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        fetchTickets(true, page, rowsPerPage);
      }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setOnDeleting(false);
    }
  };

  const fetchTickets = async (isFirstTime, page, rowsPerPage) => {
    if (isFirstTime) {
      setOnLoading(true);
    }
  
    const token = localStorage.getItem('ASIToken');
    try {
      const response = await axios.get(`/api/v1/organizations/getTickets/${page}/${rowsPerPage}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      setTickets(response.data.tickets);
      setCount(response.data.totalCount);
    } catch (error) {
      console.error('Error fetching tickets:', error);
      toast.error('Failed to fetch tickets. Please try again later.');
    } finally {
      setOnLoading(false);
    }
  };

  useEffect(() => {
    fetchTickets(true, 0, rowsPerPage);

    const interval = setInterval(() => {
      fetchTickets(false, page, rowsPerPage);
    }, 20000);

    return () => clearInterval(interval);
  }, []);

  const goToAddTicket = () => {
    navigate('/open-ticket');
  };

  const goToTicket = (id) => {
    navigate('/ticket?ticketId=' + id);
  };

  const goToEditTicket = (id) => {
    navigate('/edit-ticket?ticketId=' + id);
  };

  const columns = [
    {
      name: 'ID',
      label: 'ID',
      options: {
        sort: true,
        sortDirection: 'desc',
      }
    },
    "Origin",
    "Application",
    "Category",
    "Title",
  //  "Description",
    
    "Opened By",
    "Assigned To",
    {
      label: "Priority",
      options: {
        filter: true,
        sort: true,
        download: true,
        customBodyRender: (value, tableMeta, updateValue) => {
          
          const getPriorityStyle = (value) => {
            const baseStyle = {
              fontSize: 12,
              fontWeight: 'bold',
              padding: '5px 10px',
              borderRadius: 5,
              display: 'inline-block',
              textTransform: 'uppercase'
            };
    
            switch(value.toUpperCase()) {
              case 'LOW':
                return { ...baseStyle, backgroundColor: '#3498db', color: '#ffffff' };
              case 'MEDIUM':
                return { ...baseStyle, backgroundColor: '#f39c12', color: '#ffffff' };
              case 'HIGH':
                return { ...baseStyle, backgroundColor: '#e67e22', color: '#ffffff' };
              case 'CRITICAL':
                return { ...baseStyle, backgroundColor: '#e74c3c', color: '#ffffff' };
              case 'MODERATE':
                return { ...baseStyle, backgroundColor: '#2ecc71', color: '#ffffff' };
              default:
                return { ...baseStyle, backgroundColor: '#95a5a6', color: '#ffffff' };
            }
          };
    
          return (
            <div style={{
              display: "flex",
              alignItems: "center",
            }}>
              <span style={getPriorityStyle(value)}>
                {value}
              </span>
            </div>
          );
        }
      }
    },
    {
  label: "Status",
  options: {
    filter: true,
    sort: true,
    download: true,
    customBodyRender: (value, tableMeta, updateValue) => {
      const getStatusStyle = (status) => {
        const baseStyle = {
          fontSize: 12,
          fontWeight: 'normal',
          padding: '5px 10px',
          borderRadius: 5,
          display: 'inline-block',
          textTransform: 'uppercase'
        };

        switch(value.toLowerCase()) {
          case 'open':
            return { ...baseStyle, backgroundColor: '#e74c3c', color: '#ffffff' };
          case 'in progress':
            return { ...baseStyle, backgroundColor: '#3498db', color: '#ffffff' };
          case 'resolved':
            return { ...baseStyle, backgroundColor: '#2ecc71', color: '#ffffff' };
          case 'on hold':
            return { ...baseStyle, backgroundColor: '#f39c12', color: '#ffffff' };
          default:
            return { ...baseStyle, backgroundColor: '#95a5a6', color: '#ffffff' };
        }
      };

      return (
        <div style={{
          display: "flex",
          alignItems: "center"
        }}>
          <span style={getStatusStyle(value)}>
            {value}
          </span>
        </div>
      );
    }
  }
},
    "Created At",
    "Updated At",
//    "Note",
    {
      label: "View",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <CButton 
            color="primary" 
            variant="outline"
            onClick={() => goToTicket(value._id)}
            className="m-2" 
            style={{ 
              width: '100%', 
              fontSize: 12, 
              fontWeight: 'bold', 
              color: '#fff', 
              display:'flex',
              flexDirection:'column', 
              alignItems:'center',
              borderColor:'#fff' 
            }}
          >
            <FaEye size={15} style={{ color: 'white' }} />
          </CButton>
        )
      }
    },
    {
      label: "Actions",
      options: {
        filter: false,
        download: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            <CButton 
              color="danger"
              variant="outline"
              className="m-1"
              onClick={() => goToEditTicket(value)}
              style={{ width: '100%', fontSize: 12, fontWeight: 'bold', color:'red', borderColor:'#2185d0' }}
            >
              <CiEdit size={15} style={{ color: 'white' }} />
            </CButton>
            <CButton 
              color="danger"
              variant="outline"
              className="m-1"
              onClick={() => handleClick(value)}
              style={{ width: '100%', fontSize: 12, fontWeight: 'bold', color:'red', borderColor:'red' }}
            >
              <MdDeleteOutline size={15} style={{ color: 'white' }} />
            </CButton>
          </div>
        )
      }
    },
  ];

  const getMuiTheme = () => createTheme({
    components: {
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            color:'#fff',
            '&:nth-child(1)': {
              width: 150,
            },
          }
        }
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            textAlign: "left",
            color:'#fff',
            '&:nth-child(1)': {
              width: 150,
            },
          }
        }
      },
    }
  });

  const options = {
    filterType: "dropdown",
    responsive: "stacked",
    elevation: 0,
    filter: true,
    download: true,
    print: true,
    search: true,
    searchOpen: true,
    viewColumns: true,
    selectableRows: false,
    rowsPerPageOptions: [10, 20, 60, 100, 150],
    pagination: true,
    textLabels: {
      body: {
        noMatch: 'No tickets found',
      }
    },
    sortOrder: {
      name: 'ID',
      direction: 'desc'
    },
    serverSide: true,
    count: count,
    page: page,
    rowsPerPage: rowsPerPage,
    onTableChange: (action, tableState) => {
      if (action === 'changePage' || action === 'changeRowsPerPage') {
        const { page, rowsPerPage } = tableState;
        fetchTickets(true, page, rowsPerPage);
        setPage(page);
        setRowsPerPage(rowsPerPage);
      }
    }
  };

  const tableData = tickets.map((ticket, index) => [
    ((page) * rowsPerPage) + (index + 1),
    ticket.tool ? `${ticket.tool} Scan` : 'Manual',
    ticket.relatedApplication ? ticket.relatedApplication.name : 'N/A',
    ticket.category,
    ticket.title,
    //ticket.description,
    ticket.openedBy ? `${ticket.openedBy.firstName} ${ticket.openedBy.lastName}` : '',
    ticket.assignedTo ? `${ticket.assignedTo.firstName} ${ticket.assignedTo.lastName}` : '',
    ticket.priority,

    ticket.status,
    new Date(ticket.createdAt).toLocaleDateString(),
    new Date(ticket.updatedAt).toLocaleDateString(),
   // ticket.note,
    ticket,
    ticket._id
  ]);

  return (
    <div className="activeScans">
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Remediations"
      >
        <text style={{ color: '#000', fontSize: 18 }}>Are you sure you want to permanently delete this ticket?</text>
        <br/><br/>
        <button onClick={() => handleConfirmation(true)} style={{ width: 100, borderWidth: 0, borderRadius:5, backgroundColor: '#5dbc53', color:'white', padding: 10 }}>Yes</button>
        <button onClick={() => handleConfirmation(false)} style={{ marginLeft: 30, borderWidth: 0, borderRadius:5, width: 100, backgroundColor: 'red', color:'white', padding: 10 }}>No</button>
      </Modal>

      <div style={{ width: '100%' }}>
        <div>
          <div style={{ marginBottom: '2rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h2>Tickets</h2>
            <CButton
              style={{
                width: 300,
                marginBottom: '2%',
                borderWidth: 0,
                fontSize: 20,
                background: '#89181b'
              }}
              onClick={goToAddTicket}
              color="primary"
              className="px-3"
            >
              <AiOutlineAppstoreAdd size={24} style={{ color: 'white' }} />
              <span style={{marginLeft:15, color:'#fff'}}>Open a New Ticket</span>
            </CButton>
          </div>

          {onLoading ? (
            <ShimmerTable row={8} col={10} />
          ) : (
            <ThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                style={{ height: "57vh" }}
                data={tableData}
                columns={columns}
                options={options}
              />
            </ThemeProvider>
          )}
        </div>
      </div>
    </div>
  );
};

export default Tickets;