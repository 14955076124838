import React, { useState, useEffect, useRef } from "react";
import { CFormInput, CButton, CFormSelect, CTable, CToast, CToastBody, CToaster } from '@coreui/react'
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom'
import { CSSProperties } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import axios from 'axios';
import { FaRegCopy } from "react-icons/fa";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ShimmerTable, ShimmerTitle, ShimmerCircularImage } from "react-shimmer-effects";


import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

import ResultAPIsIntegration from './resultAPIs'

import ApacheAntIntegration from './cicd/apacheAnt'
import ApacheMavenIntegration from './cicd/apacheMaven'
import AtlassianBambooIntegration from './cicd/atlassianBamboo'
import AtlassianBitbucketIntegration from './cicd/atlassianBitbucket'
import AWSCodeStarIntegration from './cicd/awsCodeStar'
import AzureDevOpsIntegration from './cicd/azureDevOps'
import CircleCIIntegration from './cicd/circleCI'
import CodeShipIntegration from './cicd/codeship'
import GitHubActionsIntegration from './cicd/githubActions'
import GitLabIntegration from './cicd/gitlab'
import GradleIntegration from './cicd/gradle'
import JenkinsIntegration from './cicd/jenkins'
import JetBrainsTeamCityIntegration from './cicd/jetbrainsTeamCity'
import TravisCIIntegration from './cicd/travisCI'



import { CopyToClipboard } from 'react-copy-to-clipboard';

const CICDIntegrations = () => {

  const navigate = useNavigate()
  const [organization, setOrganization] = useState({})
  const [onLoading, setOnLoading] = useState(false);

  const [clientId, setClientId] = useState('');
  const [clientIdCopied, setClientIdCopied] = useState(false);

  const [clientSecret, setClientSecret] = useState('');
  const [clientSecretCopied, setClientSecretCopied] = useState(false);

  const toaster = useRef()

  const override: CSSProperties = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  useEffect(() => {

    window.scrollTo(0, 0);

    loadOrganizationDetails();

  }, []);

  useEffect(() => {
    // console.log('onLoading', onLoading)

  }, [onLoading]);


  const loadOrganizationDetails = async (theScanId) => {

    setOnLoading(true);

    const data = {
      scanId: theScanId,
    };

    const token = localStorage.getItem('ASIToken');
    const response = await axios.get('api/v1/organizations/getOrganizationDetails/', {
      headers: { Authorization: `Bearer ${token}` },
    });

    setOrganization(response.data.organization);
    setClientId(response.data.organization.clientId)
    setClientSecret(response.data.organization.clientSecret)

    setOnLoading(false);

  };


  const notifyClientIdCopied = async () => {

    toast('Client ID copied to the clipboard', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })

  }

  const notifyClientSecretCopied = async () => {

    toast('Client secret copied to clipboard', {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    })

  }



  return (

    <div style={{ overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>
      <div style={{ width: '100%' }}>     

        
      <Tabs className="addAgentPage">
            <TabList style={{ borderWidth: 0, display: 'flex', justifyContent: 'flex-start', flexWrap:'wrap' }}>
              <Tab style={{ borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000', fontSize:14}}>Apache Ant</span>
              </Tab>

              <Tab style={{  borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000',fontSize:14 }}>Apache Maven</span>
              </Tab>

              <Tab style={{  borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000', fontSize:14}}>Atlassian Bamboo</span>
              </Tab>

              <Tab style={{  borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000',fontSize:14 }}>Atlassian Bitbucket</span>
              </Tab>

              <Tab style={{  borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000',fontSize:14 }}>AWS CodeStar</span>
              </Tab>

              <Tab style={{ borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000',fontSize:14 }}>Azure DevOps</span>
              </Tab>

              <Tab style={{  borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000',fontSize:14 }}>CircleCI</span>
              </Tab>

            
              <Tab style={{  borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000', fontSize:14}}>GitHub Actions</span>
              </Tab>

              <Tab style={{  borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000',fontSize:14 }}>GitLab</span>
              </Tab>

              <Tab style={{  borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000', fontSize:14}}>Gradle</span>
              </Tab>

            

              <Tab style={{  borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000', fontSize:14}}>Jenkins</span>
              </Tab>

              <Tab style={{  borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000',fontSize:14 }}>JetBrains TeamCity</span>
              </Tab>
              

              <Tab style={{  borderWidth: 0, textAlign: 'center' }}>
                <span style={{ color: '#000',fontSize:14 }}>TravisCI</span>
              </Tab>

            </TabList>

            <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

              <ApacheAntIntegration/>

            </TabPanel>

            <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

              <ApacheMavenIntegration/>

            </TabPanel>

            <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

              <AtlassianBambooIntegration/>

            </TabPanel>

            <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

              <AtlassianBitbucketIntegration/>

            </TabPanel>

            <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

              <AWSCodeStarIntegration/>

            </TabPanel>

            <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

              <AzureDevOpsIntegration/>

            </TabPanel>

            <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

              <CircleCIIntegration/>

            </TabPanel>

           
            <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

              <GitHubActionsIntegration/>

            </TabPanel>

            <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

              <GitLabIntegration/>

            </TabPanel>

            <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

              <GradleIntegration/>

            </TabPanel>

            

            <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

              <JenkinsIntegration/>

            </TabPanel>

            <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

              <JetBrainsTeamCityIntegration/>

            </TabPanel>
           

            <TabPanel style={{ padding: 30, backgroundColor: 'white', borderRadius: 5 }}>

              <TravisCIIntegration/>

            </TabPanel>


        </Tabs>
      </div>
    </div>
  )
}

export default CICDIntegrations