import React, { useState, useEffect, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
  CFormLabel,
  CFormSelect,
  CTextarea
} from '@coreui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ShimmerTable } from "react-shimmer-effects";
import { validateEntityName } from '../../../../helpers/validation';

const Ticket = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [updateText, setUpdateText] = useState("");
  const [attachments, setAttachments] = useState([]);
  const [theStatus, setTheStatus] = useState("");
 
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);  

  const [users, setUsers] = useState([]);
  const [assignedTo, setAssignedTo] = useState({});
  
  const [ticket, setTicket] = useState({});
  const [ticketId, setTicketId] = useState("");  

  const [errors, setErrors] = useState({
    updateText: '',
    attachments: '',
    assignedTo: '',
    general: ''
  });

  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const toaster = useRef();   

  const encodeScriptContent = (content) => {
    return content.replace(/<script|<\/script|javascript:|on\w+=/gi, match => {
      return match.replace(/</g, '&lt;').replace(/>/g, '&gt;');
    });
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    setAttachments(selectedFiles);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    var arr = location.search.split('=');
    var theTicketId = arr[1];
    setTicketId(theTicketId);
    fetchUsers();
    loadTicketDetails(theTicketId);
  }, []); 

  const [user, setUser] = useState({});
  
  useEffect(() => {
    getUserDetails();
  }, []);

  const getUserDetails = () => {
    setUser(JSON.parse(localStorage.getItem('ASIUser')));
  };

  const loadTicketDetails = async (theTicketId) => {
    setLoading(true);
    const token = localStorage.getItem('ASIToken');
    const response = await axios.get('api/v1/organizations/getTicketDetails/'+theTicketId, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setTicket(response.data.ticket); 
    setTheStatus(response.data.ticket.status);   
    setLoading(false);
  };

  const fetchUsers = async () => {
    setLoading(true);
    const token = localStorage.getItem('ASIToken');
    const response = await axios.get(`/api/v1/organizations/getOrganizationUsers`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    setUsers(response.data.users);
    if (response.data.users.length > 0) {
      setAssignedTo(response.data.users[0]._id);
    } else {
      setAssignedTo('');
    }
    setLoading(false);
  };  

  const validateForm = () => {
    let isValid = true;
    let newErrors = {
      updateText: '',
      attachments: '',
      assignedTo: '',
      general: ''
    };

    if (updateText.trim() === '') {
      newErrors.updateText = 'Please add an update.';
      isValid = false;
    } else if (updateText.length > 10000) {
      newErrors.updateText = 'Update text must not exceed 10,000 characters.';
      isValid = false;
    }

    if (!assignedTo) {
      newErrors.assignedTo = 'Please assign the ticket to a user.';
      isValid = false;
    }

    for (let i = 0; i < attachments.length; i++) {
      if (isExceeding100MB(attachments[i])) {
        newErrors.attachments = 'One or more files exceed the 100MB limit.';
        isValid = false;
        break;
      }
    }

    setErrors(newErrors);
    return isValid;
  };

  const isExceeding100MB = (file) => {
    const fileSizeInBytes = file.size;
    const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
    return fileSizeInMB > 100;
  };

  const addTicketUpdate = () => {
    if (validateForm()) {
      setLoading(true);      
      const bearerToken = localStorage.getItem('ASIToken');
      const formData = new FormData();
      formData.append('ticketId', ticket._id);
      formData.append('updateText', encodeScriptContent(updateText));
      formData.append('assignedTo', assignedTo);
      formData.append('status', theStatus);

      for (let i = 0; i < attachments.length; i++) {
        formData.append('ticketUpdateAttachments', attachments[i]);
      }

      const headers = new Headers();
      headers.append('Authorization', `Bearer ${bearerToken}`);
      
      fetch(global.backendUrl+'/api/v1/organizations/addTicketUpdate', {
        method: 'POST',        
        headers: headers,
        body: formData
      })
      .then(response => response.json())
      .then(data => {
        if(data.hasOwnProperty('error')){
          setErrors(prevErrors => ({ ...prevErrors, general: data.error }));
          setLoading(false);
        }
        else if(data.hasOwnProperty('err')){
          setLoading(false);
          setErrors(prevErrors => ({ ...prevErrors, general: "Something went wrong. Please try again" }));
        } else {
          setSubmissionSuccess(true);
          setLoading(false);
          toast('Ticket updated', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          setTicket(data.ticket);
          setUpdateText('');
          setAttachments([]);
        }
      })
      .catch(error => {
        console.error(error);
        setLoading(false);
        setErrors(prevErrors => ({ ...prevErrors, general: "An error occurred. Please try again." }));
      });
    }   
  };

  const goBack = (e) => {
    e.preventDefault();
    navigate('/tickets');
  };

  return (
    <div style={{ display: 'flex', overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>
      <div style={{ width: '80%' }}>
        <div>
          <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: "center", }}>

            <h3>Ticket #{ticket.ticketId}</h3>

{ticket && ticket.status && 
            <div style={{ display: "flex", alignItems: "center", flexDirection:'row',  }}>
        <span style={{
          fontSize: '12px',
          fontWeight: 'normal',
          padding: '5px 10px',
          borderRadius: '5px',
          display: 'inline-block',
          textTransform: 'uppercase',
          backgroundColor: (() => {
            switch(ticket.status.toLowerCase()) {
              case 'open': return '#e74c3c';
              case 'in progress': return '#3498db';
              case 'resolved': return '#2ecc71';
              case 'on hold': return '#f39c12';
              default: return '#95a5a6';
            }
          })(),
          color: '#ffffff'
        }}>
          {ticket.status}
        </span>
      </div>
}



            <CButton
              onClick={goBack}
              style={{
                width: 300,
                marginBottom: '2%',
                marginRight: 20,
                borderWidth: 0,
                fontSize: 20,
                borderColor: '#fff',
                borderWidth: 1,
                color: '#fff',
                background: 'transparent'
              }}
              color="primary"
              className="px-3"
            >
              <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight: 10 }} />
              Back to Tickets
            </CButton>
          </div>

          {!loading ? (
            <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom:30, backgroundColor: '#252B3B', padding: 15 }}>
              <h4>{ticket.title}</h4>
              <p>{ticket.description}</p>

              {ticket.status === 'OPen' && (
                <span style={{background:'red', color:'black', borderRadius:5, padding:5, width:100, textAlign:'center'}}>{ticket.status}</span>
              )}
              {ticket.status === 'In Progress' && (
                <span style={{background:'yellow', color:'black', borderRadius:5, padding:5, width:100, textAlign:'center'}}>{ticket.status}</span>
              )}
              {ticket.status === 'On Hold' && (
                <span style={{background:'grey', color:'black', borderRadius:5, padding:5, width:100, textAlign:'center'}}>{ticket.status}</span>
              )}
              {ticket.status === 'Resolved' && (
                <span style={{background:'green', color:'black', borderRadius:5, padding:5, width:100, textAlign:'center'}}>{ticket.status}</span>
              )}

              <span style={{color:'#fff',marginTop:15, fontSize:13}}>Assigned To: {ticket.assignedTo ? ticket.assignedTo.firstName + ' ' + ticket.assignedTo.lastName : ''}</span>
              <span style={{color:'#fff',marginTop:15, fontSize:13}}>Opened At: {(new Date(ticket.createdAt)).toLocaleDateString()} {(new Date(ticket.createdAt)).toLocaleTimeString()}</span>
            </div>
          ) : (
            <ShimmerTable row={8} col={10} />
          )}

          {!loading ? (
            <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', marginBottom:30, backgroundColor: '#252B3B', padding: 15 }}>
              {ticket.ticketUpdates && ticket.ticketUpdates.map((update, index) => (
                <div key={index} style={{ backgroundColor: '#251C3B', padding: 15, marginTop:10}}>
                  <p style={{ color: 'white' }}>{update.updateText}</p>
                  <div>
                    <span style={{ color: 'white', fontSize:13 }}>Updated By: {update.updatedBy.firstName} {update.updatedBy.lastName}</span><br/>
                    <span style={{ color: 'white', fontSize:13 }}>Updated At: {(new Date(update.createdAt)).toLocaleDateString()} {(new Date(update.createdAt)).toLocaleTimeString()}</span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <ShimmerTable row={8} col={10} />
          )}

          {loading ? (
            <ShimmerTable row={8} col={10} />
          ) : (
            <div style={{ width: '100%', backgroundColor: '#252B3B', padding: 15 }}>
              <CFormLabel htmlFor="formTextarea" style={{ marginTop: 30, color: 'white' }}>Add update below</CFormLabel>
              <CInputGroup className="" style={{ flexDirection: 'column' }}>
                <textarea
                  id="formTextarea"
                  placeholder="Add update here"
                  autoComplete="updateText"
                  className="form-control white-input"
                  value={updateText}
                  onChange={(e) => setUpdateText(e.target.value)}
                  style={{ width: '100%', resize: 'vertical', minHeight: '100px' }}
                  maxLength={10000}
                />
                {errors.updateText && <div style={{ color: 'red', marginTop: '5px' }}>{errors.updateText}</div>}
                <div style={{ color: 'white', marginTop: '5px' }}>{updateText.length}/10000 characters</div>
              </CInputGroup>

              <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color:'white' }}>Add file(s)</CFormLabel><br/>
              <CInputGroup className="" style={{ flexDirection: 'column', }}>
                <CFormInput
                  placeholder="Upload files(s)"
                  autoComplete="username"
                  type="file" 
                  className="white-input"
                  size="sm"
                  id="inputFile"
                  multiple
                  onChange={handleFileChange}
                  style={{ width: '100%' }}
                />
                {errors.attachments && <div style={{ color: 'red', marginTop: '5px' }}>{errors.attachments}</div>}
              </CInputGroup>    

              <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Status</CFormLabel>
              <CInputGroup className="" style={{ flexDirection: 'column' }}>
                <CFormSelect
                  id="theStatus"
                  className="white-input"
                  value={theStatus}
                  onChange={(e) => setTheStatus(e.target.value)}
                  style={{ width: '100%' }}
                >
                  <option value="Open">OPEN</option>
                <option value="In Progress">IN PROGRESS</option>
                <option value="On Hold">ON HOLD</option>
                <option value="Resolved">RESOLVED</option>
                </CFormSelect>
              </CInputGroup>     

              <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Assigned To</CFormLabel>
              <CInputGroup className="" style={{ flexDirection: 'column' }}>
                <CFormSelect
                  id="assignedTo"
                  className="white-input"
                  onChange={(e) => setAssignedTo(e.target.value)}
                  disabled={user.role === 'Tester'}
                  style={{ width: '100%' }}
                >
                  {users.map(user => (
                    <option key={user._id} value={user._id}>
                      {user.firstName} {user.lastName}  ({user.email})
                    </option>
                  ))}
                </CFormSelect>
                {errors.assignedTo && <div style={{ color: 'red', marginTop: '5px' }}>{errors.assignedTo}</div>}
              </CInputGroup>    

              {errors.general && <div style={{ color: 'red', marginTop: '15px' }}>{errors.general}</div>}

              <CButton
                style={{
                  width: '100%',
                  marginTop: '3%',
                  marginBottom: '2%',
                  borderWidth: 0,
                  fontSize: 20,
                  background: '#89181b'
                }}
                color="primary"
                className="px-3"
                onClick={addTicketUpdate}
                disabled={submitting}
              >
                {submitting ?
                  <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                  :
                  'Submit Update'
                }
              </CButton>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Ticket;