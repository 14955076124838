import React, { useState, useEffect } from "react";
import axios from 'axios';
import Chart from 'react-apexcharts';

const ApplicationFindingsDiscoveredVsResolved = () => {
  const [applicationFindings, setApplicationFindings] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    getDashboardData();
    
  }, []);

  const getDashboardData = async () => {

    setIsLoading(true);
    setError(null);
    try {
      const token = localStorage.getItem('ASIToken');
      const response = await axios.get(`/api/v1/users/getApplicationFindingsDiscoveredVsResolved`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      console.log("API Response:", response.data); // Log the response
      setApplicationFindings(response.data);
    } catch (error) {
      console.error("Error fetching application findings:", error);
      setError("Failed to fetch data");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <div className="spinner"></div>;
  if (error) return <p>Error: {error}</p>;
  if (!applicationFindings) return <p>No data available</p>;

  const resolvedCount = applicationFindings.resolved || 0;
  const openCount = applicationFindings.open || 0;

  if (resolvedCount === 0 && openCount === 0) {
    return <p>No findings data available</p>;
  }

  const chartOptions = {
    chart: {
      type: 'donut',
    },
    labels: ['Resolved', 'Open'],
    colors: ['#36A2EB', '#FF6384'],
    legend: {
      position: 'bottom',
      onItemHover: {
        highlightDataSeries: false
      }
    },
  };

  const chartSeries = [resolvedCount, openCount];

  return (
    <div className="dashboardCard" style={{
      display: 'flex', flexDirection: 'column', backgroundColor: '#fff',
      borderRadius: 10, padding: 20, width: '47%'
    }}>
      <span style={{ fontSize: 15 }}>APPLICATION FINDINGS (DISCOVERED VS RESOLVED)</span>
      <hr style={{ borderColor: '#fff' }} />

      <Chart
        options={chartOptions}
        series={chartSeries}
        type="donut"
        width="100%"
        height="300"
      />

      <div style={{ marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <p>Total Findings: {resolvedCount + openCount}</p>
        <p>Resolved: {resolvedCount}</p>
        <p>Open: {openCount}</p>
      </div>


      <style>
        {`
          .spinner {
            border: 4px solid rgba(0, 0, 0, 0.1);
            width: 36px;
            height: 36px;
            border-radius: 50%;
            border-left-color: #09f;
            animation: spin 1s ease infinite;
          }

          @keyframes spin {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        `}
      </style>
    </div>


  );
};

export default ApplicationFindingsDiscoveredVsResolved;