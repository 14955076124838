import React from 'react';

const AWSCodeStarIntegration = () => {

  return (
    <div>
      <h3 className="darkText">AWS CodeStar Integration Instructions for SAST and SCA Scans</h3>

      <hr/>

    
    </div>
  );
};

export default AWSCodeStarIntegration;