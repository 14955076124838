import React, { useState, useEffect, useRef } from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';
import { useLocation } from 'react-router-dom';

import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
  CFormLabel,
  CFormSelect,
  CTextarea
} from '@coreui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { IoMdArrowRoundBack } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { ShimmerTable } from "react-shimmer-effects";
import { validateEntityName } from '../../../../helpers/validation';

const EditBusinessUnit = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [businessUnitName, setBusinessUnitName] = useState("");
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [businessUnit, setBusinessUnit] = useState({});
  const [businessUnitId, setBusinessUnitId] = useState('');

  const [error, setError] = useState('');
  const [submissionSuccess, setSubmissionSuccess] = useState(false);

  const toaster = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    var arr = location.search.split('=');
    var theBusinessUnitId = arr[1];
    setBusinessUnitId(theBusinessUnitId);
    loadBusinessUnitDetails(theBusinessUnitId);
  }, []);

  const loadBusinessUnitDetails = async (theBusinessUnitId) => {
    setLoading(true);
    const token = localStorage.getItem('ASIToken');
    try {
      const response = await axios.get('api/v1/organizations/getBusinessUnitDetails/'+theBusinessUnitId, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBusinessUnit(response.data.data);
      setBusinessUnitName(response.data.data.name);
    } catch (error) {
      console.error("Error loading business unit details:", error);
      setError("Failed to load business unit details. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const validateForm = () => {
    if (!validateEntityName(businessUnitName)) {
      setError('Invalid business unit name. Only letters, numbers, spaces, underscores, dots, #, @ and hyphens are allowed (max 100 characters).');
      return false;
    }
    setError('');
    return true;
  };

  const editBusinessUnit = () => {
    if (validateForm()) {
      setSubmitting(true);
      const bearerToken = localStorage.getItem('ASIToken');

      const requestData = {
        id: businessUnit._id,
        name: businessUnitName,
      };

      fetch(global.backendUrl + '/api/v1/organizations/editBusinessUnit', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${bearerToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      })
        .then(response => response.json())
        .then(data => {
          if (data.hasOwnProperty('error')) {
            setError(data.error);
            setSubmitting(false);
          }
          else if (data.hasOwnProperty('err')) {
            setSubmitting(false);
            setError("Something went wrong. Please try again");
          } else {
            setSubmissionSuccess(true);
            setSubmitting(false);
            toast('Business unit updated', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            navigate('/business-units');
          }
        })
        .catch(error => {
          console.error(error);
          setSubmitting(false);
          setError("An error occurred. Please try again.");
        });
    }
  };

  const goBack = (e) => {
    e.preventDefault();
    navigate('/business-units');
  };

  return (
    <div style={{ display: 'flex', overflow: "scroll", position: 'relative', overflowY: 'hidden', overflowX: 'hidden', }}>
      <div style={{ width: '60%' }}>
        <div>
          <div style={{ marginBottom: '0rem', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <h2>Editing Business Unit</h2>
            <CButton
              onClick={goBack}
              style={{
                width: 300,
                marginBottom: '2%',
                marginRight: 20,
                borderWidth: 0,
                fontSize: 20,
                borderColor: '#fff',
                borderWidth: 1,
                color: '#fff',
                background: 'transparent'
              }}
              color="primary"
              className="px-3"
            >
              <IoMdArrowRoundBack size={25} style={{ color: '#fff', marginRight: 10 }} />
              Back to Business Units
            </CButton>
          </div>

          {loading ? (
            <ShimmerTable row={8} col={10} />
          ) : (
            <div style={{ width: '100%', backgroundColor: '#252B3B', padding: 15 }}>
              <CFormLabel htmlFor="formFileSm" style={{ marginTop: 30, color: 'white' }}>Business Unit Name</CFormLabel>
              <CInputGroup className="" style={{ flexDirection: 'column' }}>
                <CFormInput
                  placeholder="Business Unit Name"
                  autoComplete="businessUnitName"
                  className="white-input"
                  onChange={(e) => setBusinessUnitName(e.target.value)}
                  value={businessUnitName}
                  style={{ width: '100%' }}
                  maxLength={100}
                />
                {error && <div style={{ color: 'red', marginTop: '5px' }}>{error}</div>}
              </CInputGroup>

              <CButton
                style={{
                  width: '100%',
                  marginTop: '3%',
                  marginBottom: '2%',
                  borderWidth: 0,
                  fontSize: 20,
                  background: '#e50202'
                }}
                color="primary"
                className="px-3"
                onClick={editBusinessUnit}
                disabled={submitting}
              >
                {submitting ?
                  <CircularProgress color="primary" size={24} style={{ marginTop: 10, color: '#fff' }} />
                  :
                  'Save Business Unit'
                }
              </CButton>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default EditBusinessUnit;